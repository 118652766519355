<template>
    <div class="detailed-events-wrapper">
        <h3></h3>
        <table class="table table-bordered hour-detail">
            <thead>
                <tr>
                    <th v-for="(segment, i) in segments" class="text-center">{{ moment(`${detailEvents.ind}:${segment.label}`, 'H:m').format('HH:mm') }} <br>-<br> {{ moment(`${detailEvents.ind}:${segment.label}`, 'H:m').add(5, 'minutes').format('HH:mm') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tr-detail-hour">
                    <td v-for="(segment, i) in segments"></td>
                    <div class="event-point detail-point" v-for="(event, k) in events" v-if="event.status === 'online'"
                        :key="`${k}`" :content="event.title" v-tippy="{ placement: 'top', arrow: true }"
                        :class="event.classBind" :style="{ left: `${event.detailPercent}%` }
                        "></div>
                    <div style="position: absolute; width: 100%; height: 100%; left: 0; right: 0; padding: 0;">
                        <span class="pause-statement" :class="[tt.classBind]"
                            :style="{
                            'left': `${ tt.isStartHere ? ((parseInt(tt.start.format('m'))/60) * 100) +'%' : 'auto'}`,
                            'right': `${ tt.isEndHere ? (100 -((parseInt(tt.end.format('m'))/60) * 100)) + '%' : 'auto' }`,
                            'width': `${ tt.isStartHere && tt.isEndHere ? ((parseInt(tt.end.format('m')) - parseInt(tt.start.format('m'))) /60) * 100 + '%': '100%'}`
                            }" 
                            :content="tt.title" v-tippy="{ placement: 'top', arrow: true }"
                        v-for="tt of detailEvents.pauses"></span>
                    </div>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { isUndefined } from "underscore"
export default {
    props: ['detailEvents'],
    setup() {
        return {
            moment
        }
    },
    // data() {
    //     // return {
    //     //     hour
    //     // }
    // },
    computed: {
        ...mapGetters('monitoring', ['colors']),
        segments() {
            const segments = [];
            for (let index = 0; index < 12; index++) {
                segments.push({label: index * 5})
            }
            return segments;
        },
        events() {
            return !isUndefined(this.detailEvents.events) ? this.detailEvents.events.filter(item => {
                return moment(item.created_at, 'X').format('H') == this.detailEvents.ind;
            }).map(event => Object.assign(event, {detailPercent: (parseInt(moment(event.created_at, 'X').format('m'))/60) * 100 })) : []
        }
    }
}
</script>

<style>
    .hour-detail {
        position: relative;
        min-width: 765px;
    }
    .hour-detail th {
        width: calc(100% / 12);
        font-size: 12px;
    }
    .hour-detail td {
        height: 120px;
    }
    .event-point.detail-point {
        width: calc((100% / 12) * 0.2);
        padding: 0;
    }
    @media (max-width: 991px) {
        .detailed-events-wrapper {
            overflow-x: scroll;
        }
    }
    @media (min-width: 992px) {
        .detailed-events-wrapper {
            overflow-x: hidden;
        }
    }
    .hour-detail .pause-statement {
        position: absolute;
        top: 0;
        height: 20%;
        width: 100%;
        display: inline-block;
        background: #c8c8c8;
        border-bottom: 1px solid #bebebe;
    }
    .pause-statement.color-0 {
        top: 0;
    }

    .pause-statement.color-1 {
        top: 20%;
    }

    .pause-statement.color-2 {
        top: 40%;
    }

    .pause-statement.color-3 {
        top: 60%;
    }

    .pause-statement.color-4 {
        top: 80%;
    }
    .tr-detail-hour {
        overflow-x: hidden;
        position: relative;
    }
</style>