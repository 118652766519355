import moment from "moment";
let today = new Date()
today.setHours(0, 0, 0, 0)

let endOfToday = new Date()
endOfToday.setHours(23, 59, 59, 0);


let yesterday = new Date()
yesterday.setDate(today.getDate() - 1)
yesterday.setHours(0, 0, 0, 0);


let date = new Date();
let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

export default {
    calendar: {
        en: {
            direction: 'ltr',
            format: 'dd.mm.yyyy',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 1
        },
        ru: {
            direction: 'ltr',
            format: 'dd.mm.yyyy',
            separator: ' - ',
            applyLabel: 'Применить',
            cancelLabel: 'Отмена',
            weekLabel: 'W',
            customRangeLabel: 'Пользовательский диапазон',
            daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            monthNames: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            firstDay: 1
        },
        ua: {
            direction: 'ltr',
            format: 'dd.mm.yyyy',
            separator: ' - ',
            applyLabel: 'Застосувати',
            cancelLabel: 'Скасувати',
            weekLabel: 'W',
            customRangeLabel: 'Спеціальний діапазон',
            daysOfWeek: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            monthNames: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            firstDay: 1
        }
    },
    ranges: {
        en: {
            'Today': [today, endOfToday],
            'Yesterday': [yesterday, yesterday],
            'This week': [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()],
            'This month': [firstDay, lastDay],
            'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'This quarter': [new Date(today.getFullYear(), today.getMonth() - 2, 1), lastDay],
        },
        ru: {
            'Сегодня': [today, endOfToday],
            'Вчера': [yesterday, yesterday],
            'Эта неделя': [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()],
            'Этот месяц': [firstDay, lastDay],
            'Прошлый месяц': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Этот квартал': [new Date(today.getFullYear(), today.getMonth() - 2, 1), lastDay],
        },
        ua: {
            'Сьогодні': [today, endOfToday],
            'Вчора': [yesterday, yesterday],
            'Цей тиждень': [moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()],
            'Цей місяць': [firstDay, lastDay],
            'Минулий місяць': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Цей квартал': [new Date(today.getFullYear(), today.getMonth() - 2, 1), lastDay],
        }
    }
}