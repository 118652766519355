<template>
  <section class="card" style="margin-bottom: 3rem">
    <header class="card-header" style="padding-bottom: 0; display: flex; justify-content: space-between;align-items: center">
      <h4 class="inside-header">{{ $t("monitoring.time_online") }}</h4>
      <b-button :disabled="!isAnyActiveTarget()" class="button" variant="primary" style="text-transform: none;" @click="selectAllActivityTargets">{{$t('monitoring.show_activity')}}</b-button>
    </header>
    <div class="card-content">
      <div>
        <section>
          <multiselect v-model="selectedOptions" class="m-b-20 targets-select" :options="dropdownList" :searchable="false" :multiple="true"
            :placeholder="$t('monitoring.select_targets')"
            :disabled="this.isLoading || progress < 100 || targetLoad" :max="5"
            track-by="id"
            :clear-on-select="false" selectLabel="" deselectLabel="" selectedLabel="" :preserve-search="true"
            :close-on-select="selectedOptions.length >= 4">
            <template slot="maxElements">{{ $t('monitoring.max_targets') }}</template>
            <template slot="noOptions">{{ $t('monitoring.no_targets') }}</template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">{{ props.option.alias ? props.option.alias :
                $t('monitoring.' + props.option.param) }}:
                {{ props.option.value }}</div>
            </template>
            <template slot="tag" slot-scope="{option}">
              <span class="multiselect__tag"
                :class="[`color_${tagIndex(option)}`,
                  {'without-service':sourceNames !== 'event_monitors' && !selectedSources.monitoring_params.includes(option.param)}]">
                <span>{{ option.alias ? option.alias :
                  $t('monitoring.' + option.param) }}:
                  {{ option.value }}</span>
                <i tabindex="1" class="multiselect__tag-icon" @click="removeResult(option)"></i>
              </span>
            </template>
          </multiselect>
          <multiselect 
            track-by="name"
            :disabled="!selectedOptions.length || progress < 100 || targetLoad"
            :placeholder="$t('monitoring.social_services')"
            v-model="selectedSources" 
            :options="sourcesForSelect" 
            :searchable="false"
            :clear-on-select="false" 
            selectLabel="" 
            deselectLabel="" 
            selectedLabel="" 
            :preserve-search="true"
            :close-on-select="true"
            :allow-empty="false"
            :multiple="false">
            <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex"><monitoring-icons class="m-r-5" v-if="!isUndefined(option.icon_url)" :source="option.name" :serviceSelected="true" />{{ option.name === 'VKMonitor' ? 'VKontakte' : option.name === 'event_monitors' ? $t('monitoring.event_monitors') : option.name }}</div>
            </template>
            <template slot="option" slot-scope="{ option }"><div class="option__desc"><monitoring-icons v-if="!isUndefined(option.icon_url)" class="m-r-5" :source="option.name" :serviceSelected="true" />{{ option.name === 'VKMonitor' ? 'Vkontakte' : option.name === 'event_monitors' ? $t('monitoring.event_monitors') : option.name }}</div>
<!--              <div class="option__desc"><img v-if="!isUndefined(option.icon_url)" class="img-20" :src="option.icon_url" alt=""> {{ option.name === 'VKMonitor' ? 'Vkontakte' : option.name === 'event_monitors' ? $t('monitoring.event_monitors') : option.name }}</div>-->
              
            </template>
            <template slot="tag" slot-scope="{option}">
              <span class="multiselect__tag">
<!--                <img class="img-16 tag-img" :src="option.icon_url" alt="">-->
                <monitoring-icons class="m-r-5" :source="option.name" :serviceSelected="true" />
                <span>{{ option.name }}</span>
                <i tabindex="1" class="multiselect__tag-icon" @click="removeSource(option)"></i>
              </span>
            </template>
          </multiselect>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from 'vue-multiselect';
import MonitoringIcons from "@/components/monitoring/monitoringIcons";
import {difference, isUndefined, some} from "underscore";
const includesAny = (arr, values) => values.some(v => arr.includes(v));
const nullObject = {
        name: 'event_monitors'
      }

export default {
  name: "monitoringFilters",
  setup() {
    return {
      isUndefined,
      some
    }
  },
  data() {
    return {
      selectedSources: nullObject,
      selectedOptions: [],
      disableFilter: false
    }
  },
  components: {MonitoringIcons, Multiselect },
  props: ['isLoading'],

  methods: {
    selectAllActivityTargets(){
      let tempArr = [];
      this.targets.forEach(target => {
        if (target.active){
          tempArr.push(target)
        }
      })
       if (tempArr.length > 0){
         this.selectedOptions = tempArr;
       }
    },

    isAnyActiveTarget(){
      let tempArr = [];
      this.targets.forEach(target => {
        if (target.active){
          tempArr.push(target)
        }
      })
      if (tempArr.length > 0){
        return true
      } else {
        return false
      }
    },



    tagIndex(tag) {
      return this.selectedOptions.findIndex((element) => tag.id === element.id)
    },
    removeResult(tag) {
      const ind = this.selectedOptions.findIndex(v => v.id === tag.id);
      if(ind >= 0) {
        this.selectedOptions.splice(
          ind,
          1
        );
      }
    },
    icon(param) {
      let nans = this.sources
      let mm = nans.data.filter((item) => { return item.monitoring_params.includes(param) })
      let ans = mm.map(item => item.icon_url);
      return ans[0];
    },
    removeSource(source) {
      const ind = this.selectedSources.findIndex(v => v.id === source.id);
        if(ind >= 0) {
          this.selectedSources.splice(
            ind,
            1
          );
      }
    }
  },
  computed: {
    ...mapGetters('monitoring', ['targets', 'sources', 'targetsFromSearch', 'colors', 'progress', 'targetLoad']),
    sourcesForSelect() {
      const monitoringParams = this.selectedOptions.map(item => item.param);
      
      if(!isUndefined(this.sources.data)) {
        return [nullObject, ...this.sources.data.filter(item => includesAny(item.monitoring_params, monitoringParams))]
      }
      return [nullObject];
    },
    dropdownList() {
      let list = [];
      this.targets.forEach(target => {
        list.push(target)
      })
      if (this.targetsFromSearch.length) {
        this.targetsFromSearch.forEach(target => {
          list.push(target);
        })
      }
      return list;
    },
    selectedIndexes() {
      return this.selectedOptions.map(item => {
        return item.id
      })
    },
    pausedIndex() {
     return this.sourceNames === 'event_monitors' 
      ? this.selectedOptions.map(item => item.id) 
      : this.selectedOptions.filter(option => this.selectedSources.monitoring_params.includes(option.param)).map(item => item.id) 
    },
    sourceNames() {
      return !this.selectedSources ? 'event_monitors' : this.selectedSources.name
    }
  },
  watch: {
    selectedOptions(value) {
      if (value.length <= 5) {
        setTimeout(() => {
          this.$store.dispatch('monitoring/getSelectedTargets', this.selectedOptions);
        }, 500);
        this.disableFilter = false;
      } else {
        this.selectedOptions.pop();
        this.disableFilter = true;
      }
    },
    dropdownList(value) {
      const diff = difference(this.selectedOptions.map(item => item.id), value.map(item => item.id))
      for(const delId of diff) {
        this.removeResult({id: delId})
      }
    },
    sourcesForSelect(value) {
      if(!value.map(item => item.name).includes(this.selectedSources.name)) {
          this.selectedSources = nullObject;
      }
      
    },
    sourceNames(value) {
      this.$store.dispatch('monitoring/getSelectedSources', value)
    },
    pausedIndex(val) {
      this.$store.dispatch('monitoring/clearPauses');
      if(val.length) {
        this.$store.dispatch('monitoring/getTasks', {
          filters: {
            target_id: val,
          }
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('monitoring/getSelectedTargets', this.selectedOptions);
  }
}
</script>

<style>
  .multiselect--disabled .multiselect__select {
    background: #fff;
  }
  .multiselect__option .option__desc {
    display: flex;
    align-items: center;
  }
  .multiselect__option .option__desc img {
    margin-right: 10px;
  }
  .monitoring .targets-select .multiselect__tag {
    color: #fff
  }
  .monitoring .multiselect__tag img {
    margin-right: 7px;
  }
  .monitoring .multiselect__tag.color_0 {
    background-color: #007bff;
  }
  .monitoring .multiselect__tag.color_1 {
    background-color: #28a745;
  }
  .monitoring .multiselect__tag.color_2 {
    background-color: #17a2b8;
  }
  .monitoring .multiselect__tag.color_3 {
    background-color: #dc3545;
  }
  .monitoring .multiselect__tag.color_4 {
    background-color:#37474f;
  }
  .monitoring .multiselect__tag-icon:hover{
    background-color: rgba(255, 255, 255, 0.2);
  }
  .monitoring .multiselect__tag.without-service {
    opacity: 0.2;
  }
  .monitoring .targets-select .multiselect__tag-icon:after{
    color: white;
  }

  .monitoring .multiselect__single {
    font-size: 14px;
    padding-left: 0;
  }
  @media (min-width: 992px) { 
    .monitoring .multiselect {
      /*height: 40px;*/
    }
  }

  .monitoring .multiselect__tags {
    padding-left: 13px;
  }

  .img-20 {
    width: 20px;
  }
  .img-16 {
    width: 16px;
  }
  .tag-img {
    -webkit-filter: drop-shadow(0px 0px 1px #fff);
    filter: drop-shadow(0px 0px 1px #fff);
  }
</style>
