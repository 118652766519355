<template>
  <div style="display: flex;justify-content: center;">
      <span  v-if="!isEdit && !target.active && !loading && currentMonitoringCount < 5 "  v-b-modal="'start_modal'+target.id"  :content="$t('monitoring.start_monitoring')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
        <i class="feather icon-play f-w-600 f-16 playColor "></i>
      </span>

      <span v-if="!isEdit && target.active && !loading" @click="stopMonitoring"  :content="$t('monitoring.stop_monitoring')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
        <i class="feather icon-pause f-w-600 f-16 stopColor "></i>
      </span>
      <span v-if="!target.active && !loading && !isEdit && currentMonitoringCount >= 5" style="display: flex;align-items: center;cursor: pointer">  <i class="feather icon-alert-circle f-w-600 f-16 text-warning "  :content="$t('monitoring.max_target_count')" v-tippy="{ placement : 'top',  arrow: true }"></i></span>
<!--      <span v-if="!isEdit && isAnyServiceCanBeSelected" style="display: flex;align-items: center;cursor: pointer">  <i class="feather icon-alert-circle f-w-600 f-16 text-warning "  :content="$t('monitoring.max_target_count')" v-tippy="{ placement : 'top',  arrow: true }"></i></span>-->


      <span v-if="typeof loading !== 'undefined' && loading" style="display: flex;align-items: center">
        <b-spinner small></b-spinner>
      </span>

      <button class="action-btn-target" :disabled="someLoading" v-if="isEdit && !loading" v-b-modal="'delete_modal'+target.id"  style="display: flex;align-items: center;cursor: pointer">
        <i class="feather icon-trash-2 f-w-600 f-16 text-c-red m-r-15" :content="$t('monitoring.delete_target')" v-tippy="{ placement : 'top',  arrow: true }"></i>
      </button>

      <button class="action-btn-target" :disabled="someLoading" v-if="isEdit && !loading" @click="editTarget"   style="display: flex;align-items: center;cursor: pointer">
        <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoring.edit_target')" v-tippy="{ placement : 'top',  arrow: true }"></i>
      </button>


      <update-target
          :ref="'updateModal'+target.id"
          :target="target"
      />

<!--    <b-modal :id="'delete_modal'+target.id" ref="delete-modal" centered hide-footer :title=" $t('monitoring.delete_target_modal')">-->
    <b-modal class="deleteTarget" :id="'delete_modal'+target.id" ref="delete-modal" centered hide-footer>
      <template #modal-title>
        <span v-html="$t('monitoring.delete_target_modal', { value: target.value })"></span>
      </template>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="unsubscribe()">{{$t('modal.actionDelete') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="hideDeleteModal()">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>



    <b-modal class="startTarget" :id="'start_modal'+target.id" ref="start-modal" centered hide-footer>
      <template #modal-title>
        <span>{{$t('monitoring.monitoring_options')}} {{target.value}}</span>
      </template>
      <div class="d-grid">
        <div>
            <span>
              <span>{{$t('monitoring.start_monitoring_for')}}</span>
              <b-form-select v-model="period" :options="periodList" style="margin-left: 8px; margin-right: 8px"></b-form-select>
              <span>{{$tc('monitoring.continue_day', period)}}</span>
              <!-- <span v-if="period < 7"></span>
              <span v-else>{{$t('monitoring.continue_days')}}</span> -->
            </span>
        </div>
        <div class="checkboxServices m-t-5">
          <span>{{$t('monitoring.monitoring_services')}}</span>
          <div v-for="source in sources.data" class="serviceSelect border-checkbox-section">
              <div class="border-checkbox-group border-checkbox-group-primary" v-if="source.monitoring_params.includes(target.param)">
                <input type="checkbox" class="border-checkbox" :id="`service_${source.id}`"
                       :disabled="!source.is_active || !serviceCanBeSelected(source.id)"
                       v-model="service_ids" :value="source.id">
                <label :for="`service_${source.id}`" class="border-checkbox-label checkboxFlex">
                  <div style="display: flex;align-items: flex-start">
                    <div style="width: auto">
                      {{ nameChange(source.name) }}
                    </div>
                    <div style="margin-left: 8px" v-if="!serviceCanBeSelected(source.id)">
                      <tippy
                          style="display: flex; margin-right: 10px"
                          interactive
                          :animate-fill="false"
                          placement="top"
                          distant="7"
                          theme="light"
                          animation="shift-toward"
                          trigger="mouseenter"
                          offset="interactive:true"
                          arrow>
                        <template v-slot:trigger>
                          <i class="feather icon-info" ></i>
                        </template>
                        <div class="alignLeft">
                          <div><span style="font-weight: 700">{{ nameChange(source.name) +': ' }}</span>{{status(source.name, target)}} </div>
                          <div>{{ $t('monitoring.next_service_check')+': '+timeLeftForNextStatusCheck(source.name, target)}}</div>
                        </div>
                      </tippy>

                    </div>
                  </div>
                </label>
              </div>
            </div>

        </div>
        <b-button style="text-transform: none" class="mt-3" variant="primary" :disabled="!( service_ids &&  service_ids.length > 0) && !canStartMonitoring" block @click="startMonitoring()">{{$t('monitoring.start_monitoring') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="hideStartModal()">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>


  </div>
</template>

<script>
import UpdateTarget from "@/components/monitoring/updateTarget";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "targetActions",
  components: {UpdateTarget},
  props:['target', 'isEdit', 'someLoading'],
  data() {
    return {
      loading: false,
      period: 30,
      service_ids: [],
      periodList: [3,7,10,14,21,30]
    }
  },

  computed: {
    ...mapGetters('monitoring', ['currentMonitoringCount','sources']),
    canStartMonitoring(){
      if (this.target.online_statuses && this.target.online_statuses.length){
        return this.target.online_statuses.some(status=> {
          if (status.status !== 'non-existent' && status.status !== 'hidden'){
            return true;
          } else if (status.status === 'non-existent' || status.status === 'hidden' ){
            return ((this.timeLeftCounter(status.updated_at) + 24) < 0 )
          }
        })
      } else {
        return true
      }
    },
  },
  methods: {



    status(source, target){
      let currentStatus;
      if (target.online_statuses.length){
        currentStatus = target.online_statuses.find(status => status.source === source)
      }
      if (typeof currentStatus !== 'undefined' && currentStatus.status){
        return this.$t('monitoring.'+ currentStatus.status)
      }
    },
    timeLeftForNextStatusCheck(source, target){
      let currentStatus = target.online_statuses.find(status => status.source === source)
      let updateDate = moment(currentStatus.updated_at, 'X').add(24, 'hour').format('DD.MM.YYYY, HH:mm')
      return updateDate
    },
    isAnyServiceCanBeSelected(){
      this.sources.data.forEach(source=> {
        if (source.monitoring_params.includes(this.target.param)){
          if ( !source.is_active || !this.serviceCanBeSelected(source.id)){
            return false
          } else {
            return true
          }
        }
      })
    },
    serviceCanBeSelected(serviceId){
      let currentService = this.target.online_statuses.find(status=> status.service_id === serviceId)
      if (typeof currentService !== 'undefined' && (currentService.status === 'non-existent' || currentService.status === 'hidden')){
        return ((this.timeLeftCounter(currentService.updated_at) + 24) < 0 )
      } else {
        return true
      }
    },
    timeLeftCounter(val){
      let left;
      let eventTime = moment(val, 'X')
      let now = moment();
      left = eventTime.diff(now, 'hours')
      return left
    },



    nameChange(val){
      let name
      if (val === 'VKMonitor'){
        name = 'VKontakte'
      } else {
        name = val
      }
      return name
    },
    showDeleteModal(){
      console.log(this.target.id)
      this.$refs['delete-modal'].show();
    },
    hideDeleteModal() {
      this.$refs['delete-modal'].hide();
    },
    hideStartModal() {
      this.service_ids = [];
      this.$refs['start-modal'].hide();
    },
    startMonitoring() {
      this.loading = true
      let payload = {
        id: this.target.id
      }
      if (this.period !== 0){
        payload.period = parseInt(this.period)
      }
      if ( this.service_ids && this.service_ids.length !== 0){
        payload.service_ids = this.service_ids
      }
      this.$store.dispatch('monitoring/startMonitoring', payload).then(()=>{
        this.$store.dispatch('monitoring/getTargets').then(() => {
          this.loading = false
          this.hideStartModal();
          this.delayUpdateTargets();
        })
      }).catch(()=>{
        this.loading = false
        this.hideStartModal();
      })
    },
    delayUpdateTargets(){
      setTimeout(()=>{
        this.loading = true
        this.$store.dispatch('monitoring/getTargets').then(()=>{
          this.loading = false
        })
      }, 120000)
    },
    stopMonitoring() {
      this.loading = true
      this.$store.dispatch('monitoring/stopMonitoring', this.target.active_task.id).then(()=>{
        this.$store.dispatch('monitoring/getTargets').then(() => {
          this.loading = false
        })
      }).catch(()=>{
        this.loading = false
      })
    },

    unsubscribe() {
      this.$refs['delete-modal'].hide();
      this.loading = true
      this.$store.dispatch('monitoring/unsubscribe', this.target.id).then(()=>{
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
    editTarget(){
      this.$refs['updateModal'+this.target.id].showDialog()
    },
  },
  beforeDestroy() {
    this.$emit('anyloading', false);
  },
  watch: {
    loading(val) {
      this.$emit('anyloading', val);
    }
  }
}
</script>

<style>
.serviceSelect .border-checkbox-section .border-checkbox-group .border-checkbox-label {
  margin-bottom: 10px;
  height: 60px;
}

.serviceSelect .user-link {
  display: inline-block;
}

.serviceSelect .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox {
  width: 100%;
}

.serviceSelect .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox .check {
  margin-right: calc(0.75em - 1px);
}

.serviceSelect .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox .control-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0;
  position: relative;
}

.serviceSelect .b-border-checkbox-group .border-checkbox-group-primarybox.checkbox .control-label:hover {
  background-color: #fff;
  overflow: visible;
  text-overflow: initial;
  text-decoration: underline;
  z-index: 99;
  padding-right: 20px;
}
.deleteTarget b, strong{
  font-weight: 600 !important;
}
.action-btn-target {
  border: 0;
  background: transparent;
}

</style>