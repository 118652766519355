<template>
  <section class="card">
    <header class="card-header" style="padding-bottom: 0;">
      <div class="content">
        <h4>{{ $t("info_from_search.from_search") }}</h4>
      </div>
    </header>
    <div class="card-content">
      <div class="content">
        <div v-for="(target, index) in targetsFromSearch" :key="index">
          <span style="line-height: 2rem;margin-left: 1rem;"> {{$t('monitoring.' + target.param)}}  : {{target.value}}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "infoFromSearch",
  computed: {
  ...mapGetters('monitoring', ['targetsFromSearch'])
  },

  beforeDestroy() {
    this.$store.dispatch('monitoring/addTargetFromSearch', [])
  }
}
</script>

<style lang="scss">
.content{
  margin-left: 1rem;
}

</style>